// breakpoints
$extra-large: 1599px;
$large: 1199px;
$tablet: 1023px;
$mobile: 767px;

// width
$containerWidth: 1410px;

// colors
$mainTextColor: #191919;
$gray-secondary-text: #5c626f;

// z-index
$popupSubscribeZ: 110;
