.hSplit {
  height: 1px;
  background: #e0e2ec;
  width: 100%;
}
.vSplit {
  width: 1px;
  background: #e0e2ec;
  height: 100%;
}
