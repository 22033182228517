@import "../../styles/_vars";

.header {
  padding-top: 32px;
  display: flex;
  align-items: center;
  width: 100%;

  .icon {
    display: flex;
    margin-right: 40px;
  }
}

@media (max-width: $extra-large) {
  .header {
    padding-top: 22px;
  }
}

@media (max-width: $tablet) {
  .header {
    padding-top: 24px;
    flex-direction: column;
    align-items: flex-start;

    .icon {
      display: flex;
      margin-right: 0px;
      margin-bottom: 24px;
    }

    &.webinarHeader {
      padding-top: 32px;
      flex-direction: row;
      align-items: center;

      .icon {
        display: flex;
        margin-right: 40px;
        margin-bottom: 0px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .header {
    &.webinarHeader {
      padding-top: 24px;
      flex-direction: column;
      align-items: flex-start;

      .icon {
        display: flex;
        margin-right: 0px;
        margin-bottom: 24px;
      }
    }
  }
}
