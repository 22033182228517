@import "../../styles/_vars";

.wrapper {
  position: relative;
  top: -56px;
  border-radius: 60px 60px 0px 0px;
  z-index: 2;
  background: #ffff;
}

.contentWrapper {
  max-width: 1410px;
  padding: 128px 0 0;
  margin: 0 auto 72px;

  .main {
    display: flex;
    margin-bottom: 128px;
    .host {
      width: 450px;
      flex-shrink: 0;
      margin-right: 32px;
      .heading {
        color: #191919;
        font-family: Lato;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
      }
      .content {
        .atc {
          margin-top: 48px;
          display: flex;
          align-items: center;
          .title {
            width: 210px;
          }
          .logo {
            background-image: url(../../assets/icons/webinar/atc.svg);
          }
        }
        .hw {
          margin-top: 48px;
          display: flex;
          align-items: center;
          .logo {
            align-self: flex-start;
          }
          .info {
            width: 272px;
            .description {
              margin: 8px auto 24px;
              color: #5c626f;
              font-family: Lato;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
            .link {
              display: flex;
              color: #191919;
              text-align: center;
              font-family: Lato;
              font-size: 13px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 0.52px;
              text-transform: uppercase;
              &:hover {
                opacity: 0.8;
              }
              &:active {
                opacity: 0.6;
              }
              .arrow {
                margin-left: 8px;
                width: 16px;
                height: 16px;
                background-image: url(../../assets/icons/webinar/right-arrow.svg);
              }
            }
          }
        }
        .title {
          color: #000;
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        .logo {
          flex-shrink: 0;
          width: 80px;
          height: 80px;
          margin-right: 24px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: top;
        }
      }
    }
    .description {
      .about {
        .paragraph {
          margin-bottom: 30px;
          color: #5c626f;
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
        }
      }
      .learn {
        margin-top: 64px;
        .items {
          .item {
            color: #5c626f;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            position: relative;
            margin-left: 26px;

            &:before {
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 50%;
              display: block;
              position: absolute;
              left: -17px;
              top: 12px;
              background-color: #5c626f;
            }
          }
        }
      }
      .heading {
        margin-bottom: 24px;
        color: #191919;
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
      }
      .button {
        margin-top: 30px;
        width: 198px;
      }
    }
  }
  .related {
    margin-bottom: 70px;
    .heading {
      color: #191919;
      font-family: Lato;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
    }
    .cards {
      margin-top: 64px;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      .card {
        width: calc((100% - 64px) / 3);
        max-width: 450px;
        border-radius: 24px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-left: 32px;
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
        &:nth-child(n + 4) {
          margin-top: 32px;
        }
        .image {
          height: 240px;
          background-size: cover;
          background-position: center top;
        }
        .body {
          padding: 32px;
          background-color: #f2f4f8;
          flex-grow: 1;
          .heading {
            color: #000;
            font-family: Lato;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            margin-bottom: 8px;
          }
          .description {
            color: #5c626f;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
          }
          .link {
            margin-top: 24px;
            display: flex;
            color: #191919;
            text-align: center;
            font-family: Lato;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.52px;
            text-transform: uppercase;
            &:hover {
              opacity: 0.8;
            }
            &:active {
              opacity: 0.6;
            }
            .arrow {
              margin-left: 8px;
              width: 16px;
              height: 16px;
              background-image: url(../../assets/icons/webinar/right-arrow.svg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: $extra-large) {
  .wrapper {
    border-radius: 40px 40px 0 0;
  }
  .contentWrapper {
    padding: 80px 24px 0;
    margin: 0 auto -20px;

    .main {
      margin-bottom: 80px;
      .host {
        width: 410px;
      }
      .description {
        .button {
          width: 195px;
        }
      }
    }
    .related {
      margin-bottom: 0;
      .cards {
        .card {
          width: calc((100% - 48px) / 3);
          margin-left: 24px;
          &:nth-child(n + 4) {
            margin-top: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: $large) {
  .wrapper {
    top: -52px;
    border-radius: 30px 30px 0px 0px;
  }

  .contentWrapper {
    margin: 0 auto -12px;

    .main {
      flex-direction: column-reverse;
      margin-bottom: 64px;
      .host {
        margin-top: 64px;
        width: 100%;
        .content {
          display: flex;
          justify-content: space-between;
          .atc {
            flex-direction: column;
            align-items: flex-start;
            .title {
              margin-top: 24px;
            }
          }
          .hw {
            width: calc(50% - 12px);
            flex-direction: column;
            .logo {
              margin-bottom: 24px;
            }
            .info {
              width: 100%;
            }
          }
        }
      }
      .description {
        .button {
          margin-top: 32px;
        }
      }
    }
    .related {
      .cards {
        margin-top: 40px;
        justify-content: center;
        .card {
          width: calc((100% - 24px) / 2);
          margin-left: 24px;

          &:nth-child(3n + 1) {
            margin-left: 24px;
          }
          &:nth-child(2n + 1) {
            margin-left: 0;
          }
          &:nth-child(n + 3) {
            margin-top: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: $tablet) {
  .contentWrapper {
    padding: 64px 16px 0;
  }
}

@media (max-width: $mobile) {
  .wrapper {
    top: -33px;
    border-radius: 24px 24px 0px 0px;
  }

  .contentWrapper {
    padding: 40px 16px 0;
    margin: 0 auto -16px;

    .main {
      .host {
        .content {
          display: block;
          .hw {
            margin-top: 24px;
            width: 100%;
          }
        }
      }
      .description {
        .heading {
          font-size: 32px;
          line-height: 42px;
        }
      }
    }
    .related {
      .cards {
        margin-top: 40px;
        .card {
          width: 100%;
          margin: 0 0 24px 0 !important;
          .image {
            height: 200px;
          }
        }
      }
    }
  }
}
