@import "../../styles/vars";
@import "../../styles/animations";

.button {
  padding: 15px 24px;
  background-color: #181a20;
  border-radius: 6px;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  height: 50px;
  width: 100%;
  font: 700 16px/20px Lato;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  transition: background-color 0.3s ease-in-out;

  &.variant-secondary {
    color: #191919;
    background-color: #ffffff;
    width: 130px;

    &:hover {
      background-color: #dde1e6;
    }
    &:active {
      transition: none;
      background-color: #bec3ca;
    }
  }

  &.variant-halfWidth {
    width: calc(50% - 8px);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:before {
    content: "";
    background: linear-gradient(45deg, #4eeaff, #000aff, #4ed4ff, #4def00);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 6px;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    left: 0;
    top: 0;
    border-radius: 6px;
  }

  &:hover {
    background-color: #3e4147;
    &:before {
      opacity: 1;
    }
  }

  &:active {
    transition: none;
    background-color: #6a6e78;
  }
}
