@import "../../styles/_vars";

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -26px;
  width: 527px;
  height: 434px;
  padding: 16px;
  flex-shrink: 0;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(20px);
  .title {
    position: relative;
    width: 100%;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 16px;
    background: rgba(255, 255, 255, 0.04);
    p {
      color: rgba(255, 255, 255, 0.6);
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 42px;
      max-width: 220px;
      &:first-child {
        color: #fff;
      }
    }
    .image {
      position: absolute;
      right: -28px;
      top: -20px;
      width: 230px;
      height: 230px;
      background: url(../../assets/icons/webinar/runner.svg) center/contain
        no-repeat;
    }
  }
  .cards {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    gap: 16px;
    .days,
    .hours,
    .minutes,
    .seconds {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-shrink: 1;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.04);
      .digit {
        color: #fff;
        font-family: Lato;
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        line-height: 70px;
      }
      p {
        margin-top: 4px;
        color: #fff;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 1.4px;
      }
    }
  }
}

@media (max-width: $extra-large) {
  .wrapper {
    top: 0px;
    .title {
      .image {
        right: -17px;
        top: -45px;
        width: 265px;
        height: 265px;
      }
    }
  }
}

@media (max-width: $large) {
  .wrapper {
    height: 369px;
    margin-top: 11px;
    width: 432px;
    .title {
      padding: 40px 24px;
      .image {
        right: calc(20% - 107px);
        top: 22px;
        width: 215px;
        height: 215px;
      }
    }
  }
}

@media (max-width: $tablet) {
  .wrapper {
    margin-top: 120px;
    width: 100%;
    height: 369px;

    .title {
      p {
        max-width: none;
      }
      .image {
        right: calc(20% - 120px);
        top: -62px;
        width: 265px;
        height: 265px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .wrapper {
    height: 490px;
    margin-top: 0px;
    .title {
      margin-bottom: 0;
      padding: 24px;
      .image {
        display: none;
      }
    }
    .cards {
      margin-top: 16px;
      flex-wrap: wrap;
      .days,
      .hours,
      .minutes,
      .seconds {
        width: calc((100% - 16px) / 2);
        flex-grow: 1;
      }
    }
  }
}
