@import "../../styles/_vars";

.wrapper {
  width: 450px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 35px 35px 18px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 2px 10px 50px 0px rgba(63, 75, 128, 0.13);
  z-index: 10;
  .title {
    color: #000;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 32px;
  }
}

@media (max-width: $large) {
  .wrapper {
    width: 415px;
    margin: 0px auto;
  }
}

@media (max-width: $tablet) {
  .wrapper {
    position: relative;
    margin: 64px 0 0;
  }
}

@media (max-width: $mobile) {
  .wrapper {
    width: 100%;
    margin: 0 auto;
    .title {
      max-width: 80%;
    }
  }
}
