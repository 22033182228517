@import "../../styles/_vars";

.wrapper {
  display: flex;
  padding: 8px 8px 8px 16px;
  align-items: center;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(20px);
  height: 74px;
  max-width: 607px;
}

.title {
  color: #ffffff;
  font: 600 14px/16px Lato;
  margin-right: 16px;
}

.logos {
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);
  height: 58px;
}

.divider {
  width: 0.537px;
  background: rgba(255, 255, 255, 0.14);
  height: 100%;
  margin: 0 16px;
}

@media (max-width: $tablet) {
  .logos {
    &.variant-webinar {
      svg {
        height: 20px;

        &:first-child {
          width: 84.537px;
        }

        &:nth-of-type(2) {
          width: 69.327px;
        }

        &:last-child {
          width: 108.939px;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .wrapper {
    height: 98px;
    border-radius: 8px;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
  }

  .title {
    margin-right: 0px;
    margin-bottom: 8px;

    &.variant-footer {
      margin-bottom: 16px;
    }
  }

  .divider {
    margin: 0 8px;
  }

  .logos {
    svg {
      height: 16px;
    }

    &.variant-webinar {
      svg {
        height: 16px;

        &:first-child {
          width: 67.63px;
        }

        &:nth-of-type(2) {
          width: 69.643px;
        }

        &:last-child {
          width: 87.665px;
        }
      }
    }
  }
}
