@import "../../styles/_vars";

.partnerCard {
  background: #f2f4f8;
  border-radius: 24px;
  flex-basis: 32.3%;
  flex-shrink: 2;
  margin-bottom: 30px;
  margin-right: 30px;
  max-width: 450px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:nth-child(3n + 3) {
    margin-right: 0px;
  }

  &:nth-last-child(-n + 2) {
    margin-bottom: 0px;
  }
}

.cardHeader {
  height: 240px;

  .cardHeaderImg {
    height: 100%;

    &.tekmetric {
      background: url(../../assets/images/parnters-cards/desktop/tekmetric.png)
        center/cover no-repeat;
    }

    &.steer {
      background: url(../../assets/images/parnters-cards/desktop/steer.png)
        center/cover no-repeat;
    }

    &.localService {
      background: url(../../assets/images/parnters-cards/desktop/localService.png)
        center/cover no-repeat;
    }

    &.technet {
      background: url(../../assets/images/parnters-cards/desktop/technet.png)
        center/cover no-repeat;
    }

    &.ctiWti {
      background: url(../../assets/images/parnters-cards/desktop/CTI_WTI.png)
        center/cover no-repeat;
    }

    &.motologic {
      background: url(../../assets/images/parnters-cards/desktop/motologic.png)
        center/cover no-repeat;
    }

    &.motovisuals {
      background: url(../../assets/images/parnters-cards/desktop/motovisuals.png)
        center/cover no-repeat;
    }

    &.motoweb {
      background: url(../../assets/images/parnters-cards/desktop/motoweb.png)
        center/cover no-repeat;
    }
  }
}

.cardBody {
  padding: 32px;
  flex: 1 0 0;
}

.cardLogo {
  margin-bottom: 32px;

  &.tekmetricLogo {
    background: url(../../assets/icons/partners-cards/tekmetric.svg)
      left/contain no-repeat;
    height: 40px;
  }

  &.steerLogo {
    background: url(../../assets/icons/partners-cards/steer.svg) left/contain
      no-repeat;
    height: 40px;
  }

  &.localServiceLogo {
    background: url(../../assets/icons/partners-cards/localService.svg)
      left/contain no-repeat;
    height: 100px;
  }

  &.technetLogo {
    background: url(../../assets/icons/partners-cards/technet.svg) left/contain
      no-repeat;
    height: 100px;
  }

  &.ctiWtiLogo {
    background: url(../../assets/icons/partners-cards/CTI_WTI.svg) left/contain
      no-repeat;
    height: 40px;
  }

  &.motologicLogo {
    background: url(../../assets/icons/partners-cards/motologic.svg)
      left/contain no-repeat;
    height: 40px;
  }

  &.motovisualsLogo {
    background: url(../../assets/icons/partners-cards/motovisuals.svg)
      left/contain no-repeat;
    height: 40px;
  }

  &.motowebLogo {
    background: url(../../assets/icons/partners-cards/motoweb.svg) left/contain
      no-repeat;
    height: 40px;
  }
}

.cardTitle {
  color: $mainTextColor;
  font: 800 30px/40px Lato;
  margin-bottom: 16px;
}

.cardDescription {
  color: #707d8f;
  font: 400 16px/26px Lato;
}

.buttonWrap {
  margin: 0 32px 32px;
  width: calc(100% - 64px);
  display: flex;
  justify-content: space-between;
}

@media (max-width: $extra-large) {
  .partnerCard {
    flex-basis: calc(50% - 15px);
    max-width: initial;

    &:nth-child(3n + 3) {
      margin-right: 30px;
    }

    &:nth-child(2n + 2) {
      margin-right: 0px;
    }
  }

  .cardHeader {
    height: 300px;

    .cardHeaderImg {
      &.tekmetric {
        background: url(../../assets/images/parnters-cards/extra-large/tekmetric-extra-large.png)
          center/cover no-repeat;
      }

      &.steer {
        background: url(../../assets/images/parnters-cards/extra-large/steer-extra-large.png)
          center/cover no-repeat;
      }

      &.localService {
        background: url(../../assets/images/parnters-cards/extra-large/localService-extral-large.png)
          center/cover no-repeat;
      }

      &.technet {
        background: url(../../assets/images/parnters-cards/extra-large/technet-extra-large.png)
          center/cover no-repeat;
      }

      &.ctiWti {
        background: url(../../assets/images/parnters-cards/extra-large/CTI_WTI-extra-large.png)
          center/cover no-repeat;
      }

      &.motologic {
        background: url(../../assets/images/parnters-cards/extra-large/motologic-extra-large.png)
          center/cover no-repeat;
      }

      &.motovisuals {
        background: url(../../assets/images/parnters-cards/extra-large/motovisuals-extra-large.png)
          center/cover no-repeat;
      }

      &.motoweb {
        background: url(../../assets/images/parnters-cards/extra-large/motoweb-extra-large.png)
          center/cover no-repeat;
      }
    }
  }
}

@media (max-width: $tablet) {
  .partnerCard {
    flex-basis: initial;
    margin-bottom: 24px;
    margin-right: 0px;
    max-width: 100%;

    &:nth-child(3n + 3) {
      margin-right: 0px;
    }

    &:nth-last-child(-n + 2) {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .cardHeader {
    height: 370px;

    .cardHeaderImg {
      &.tekmetric {
        background: url(../../assets/images/parnters-cards/tablet/tekmetric-tablet.png)
          center/cover no-repeat;
      }

      &.steer {
        background: url(../../assets/images/parnters-cards/tablet/steer-tablet.png)
          center/cover no-repeat;
      }

      &.localService {
        background: url(../../assets/images/parnters-cards/tablet/localService-tablet.png)
          center/cover no-repeat;
      }

      &.technet {
        background: url(../../assets/images/parnters-cards/tablet/technet-tablet.png)
          center/cover no-repeat;
      }

      &.ctiWti {
        background: url(../../assets/images/parnters-cards/tablet/CTI_WTI-tablet.png)
          center/cover no-repeat;
      }

      &.motologic {
        background: url(../../assets/images/parnters-cards/tablet/motologic-tablet.png)
          center/cover no-repeat;
      }

      &.motovisuals {
        background: url(../../assets/images/parnters-cards/tablet/motovisuals-tablet.png)
          center/cover no-repeat;
      }

      &.motoweb {
        background: url(../../assets/images/parnters-cards/tablet/motoweb-tablet.png)
          center/cover no-repeat;
      }
    }
  }

  .cardLogo {
    &.localServiceLogo {
      background: url(../../assets/icons/partners-cards/localService-tablet.svg)
        left/contain no-repeat;
      height: 36.5px;
    }
  }
}

@media (max-width: $mobile) {
  .partnerCard {
    margin-bottom: 16px;

    &:nth-last-child(-n + 2) {
      margin-bottom: 16px;
    }
  }

  .cardHeader {
    height: clamp(210px, calc(39vw + 69px), 370px);

    .cardHeaderImg {
      &.tekmetric {
        background: url(../../assets/images/parnters-cards/mobile/tekmetric-mobile.png)
          center/cover no-repeat;
      }

      &.steer {
        background: url(../../assets/images/parnters-cards/mobile/steer-mobile.png)
          center/cover no-repeat;
      }

      &.localService {
        background: url(../../assets/images/parnters-cards/mobile/localService-mobile.png)
          center/cover no-repeat;
      }

      &.technet {
        background: url(../../assets/images/parnters-cards/mobile/technet-mobile.png)
          center/cover no-repeat;
      }

      &.ctiWti {
        background: url(../../assets/images/parnters-cards/mobile/CTI_WTI-mobile.png)
          center/cover no-repeat;
      }

      &.motologic {
        background: url(../../assets/images/parnters-cards/mobile/motologic-mobile.png)
          center/cover no-repeat;
      }

      &.motovisuals {
        background: url(../../assets/images/parnters-cards/mobile/motovisuals-mobile.png)
          center/cover no-repeat;
      }

      &.motoweb {
        background: url(../../assets/images/parnters-cards/mobile/motoweb-mobile.png)
          center/cover no-repeat;
      }
    }
  }

  .cardBody {
    padding: 24px;
  }

  .cardLogo {
    margin-bottom: 24px;

    &.tekmetricLogo,
    &.steerLogo,
    &.ctiWtiLogo,
    &.motologicLogo,
    &.motovisualsLogo,
    &.motowebLogo {
      height: 38px;
    }

    &.localServiceLogo {
      background: url(../../assets/icons/partners-cards/localService-mobile.svg)
        left/contain no-repeat;
      height: 88px;
    }

    &.technetLogo {
      height: 80px;
    }
  }

  .cardTitle {
    font: 800 24px/32px Lato;
    margin-bottom: 8px;
  }

  .cardDescription {
    font: 400 16px/26px Lato;
  }

  .buttonWrap {
    margin: 0 24px 24px;
    width: calc(100% - 48px);
  }
}
