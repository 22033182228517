@import "../../styles/_vars";

.wrapper {
  max-width: 57.5%;
  .schedule {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .status {
      padding: 4px 8px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.3px;
      text-transform: uppercase;
    }
    .detailWrapper {
      display: flex;

      .date,
      .time,
      .duration {
        display: flex;
        margin-left: 32px;
        color: #fff;
        font-family: Lato;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 12px;
        }
      }
      .date .icon {
        background: url(../../assets/icons/webinar/clock.svg);
      }
      .time .icon {
        background: url(../../assets/icons/webinar/calendar.svg);
      }
      .duration {
        opacity: 0.8;
      }
    }
  }
  .title {
    margin: 32px 0 0;
    color: #fff;
    font-family: Lato;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
  }
  .description {
    margin-top: 24px;
    color: #c8d0da;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .links {
    display: flex;
    align-items: center;
    margin-top: 56px;
    .button {
      width: 198px;
      margin-right: 32px;
      flex-shrink: 0;
      a {
        width: 100%;
      }
    }
    .share {
      display: flex;
      align-items: center;
      color: #fff;
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      .icons {
        display: flex;
        div {
          width: 32px;
          height: 32px;
          margin-left: 12px;
          &:hover {
            opacity: 0.9;
          }
          &:active {
            opacity: 0.8;
          }
        }
        .email {
          background: url(../../assets/icons/webinar/email.svg);
        }
        .in {
          background: url(../../assets/icons/webinar/in.svg);
        }
        .x {
          background: url(../../assets/icons/webinar/x.svg);
        }
        .f {
          background: url(../../assets/icons/webinar/f.svg);
        }
      }
    }
  }
}

@media (max-width: $extra-large) {
  .wrapper {
    max-width: 52%;
  }
}

@media (max-width: $large) {
  .wrapper {
    max-width: 51%;
    margin: 0 0 100px 8px;

    .schedule {
      .detailWrapper {
        width: 100%;
        margin-top: 32px;
        .date {
          margin-left: 0px;
        }
      }
    }
  }
}

@media (max-width: $tablet) {
  .wrapper {
    max-width: 78.5%;
    margin: 0 0 0px 8px;

    .schedule {
      .detailWrapper {
        width: initial;
        margin-top: 0px;
        .date {
          margin-left: 32px;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .wrapper {
    max-width: 100%;
    margin: 0 0 64px 0;
    .schedule {
      .status,
      .detailWrapper .date,
      .detailWrapper .time,
      .detailWrapper .duration {
        margin: 0 32px 32px 0;
      }

      .detailWrapper {
        width: 100%;
        position: relative;

        .date {
          position: absolute;
          top: -52px;
          left: 132px;
        }
      }
    }
    .title {
      margin: 0;
      font-size: 32px;
      line-height: 42px;
    }
    .links {
      flex-direction: column;
      align-items: flex-start;
      .button {
        margin-bottom: 40px;
      }
    }
  }
}
