@import "../../styles/_vars";
@import "./HeroAnimations.module.scss";

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../assets/images/backgrounds/header-desktop.png")
    center/cover no-repeat;
}

.heroWrapper {
  width: 100%;
  max-width: 1410px;
  display: flex;
  flex-direction: column;
}

.hero {
  width: 100%;
  display: flex;
  padding: 15px 0 163px;
  position: relative;
  z-index: 1;
}

.heroText {
  width: 50%;
}

.heroTextWrap {
  max-width: 517px;
  display: flex;
  flex-direction: column;
  padding-top: 125px;
}

.heroTitle {
  color: #fff;
  font: 700 48px/52.8px Lato;

  .heroTitleMain {
    display: flex;
    font: 700 120px/132px Lato;
  }
}

.heroBody {
  color: #c8d0da;
  font: 400 18px/28px Lato;
  margin: 24px 0 40px;
}

.heroImgWrap {
  width: 50%;
  position: relative;
  left: 34px;
  top: -20px;
}

.heroImg {
  g[id="middle-circle-wrapper"] {
    animation: spin 73s infinite linear;
    transform-box: border-box;
    transform-origin: 226.9px 226.9px;
    -moz-transform-origin: 298.739px 312.739px;

    g[id="motoweb"] {
      animation: Rotate 73s infinite linear;
      transform-origin: center;
    }

    g[id="wti"] {
      animation: Rotate 73s infinite linear;
      transform-origin: center;
    }
  }

  g[id="middle-circle-invisible-wrapper"] {
    animation: spinMotologic 73s infinite linear;
    -moz-animation: spin 73s infinite linear;
    -moz-transform-origin: 298.739px 312.739px;
    transform-box: border-box;

    g[id="motologic"] {
      animation: Rotate 73s infinite linear;
      transform-origin: center;
      -moz-transform-origin: 27.02px 27.02px;
    }
  }

  g[id="inner-circle-wrapper"] {
    animation: spin 65s infinite linear;
    transform-origin: 163.579px 163.579px;
  }

  g[id="secondCircle"] {
    animation: spin 65s infinite linear;
    transform-origin: -5.915px 170.619px;
  }

  g[id="outer-circle-wrapper"] {
    transform-origin: 293.739px 293.739px;
    -moz-transform-origin: 298.739px 312.739px;
    animation: spin 75s infinite linear;
    transform-box: border-box;
  }

  g[id="steer"] {
    animation: Rotate 75s infinite linear;
    transform-origin: center;
    -moz-transform-origin: 33.7755px 33.7755px;
  }

  g[id="carquest"] {
    animation: Rotate 75s infinite linear;
    transform-origin: center;
  }

  g[id="motovisual"] {
    animation: Rotate 75s infinite linear;
    transform-origin: center;
  }

  g[id="miniCircle"] {
    animation: spin 75s infinite linear;
    transform-origin: 300.739px 33.07132px;
  }

  g[id="outer-circle-invisible-wrapper"] {
    animation: spinTekmetric 75s infinite linear;
    -moz-animation: spin 75s infinite linear;
    -moz-transform-origin: 298.739px 312.739px;
    transform-box: border-box;

    g[id="tekmetric"] {
      animation: Rotate 75s infinite linear;
      transform-origin: center;
      -moz-transform-origin: 33.2755px 33.2755px;
    }
  }
}

@media (max-width: $extra-large) {
  .wrapper {
    background: url("../../assets/images/backgrounds/header-extra-large.png")
      center/cover no-repeat;
  }

  .heroWrapper {
    padding: 0 26px;
    max-width: 1350px;
  }

  .hero {
    padding: 57px 0 131px;
  }

  .heroTextWrap {
    max-width: 462px;
    padding-top: 162px;
  }

  .heroTitle {
    .heroTitleMain {
      font: 700 90px/99px Lato;
    }
  }

  .heroImgWrap {
    left: -95px;
  }
}

@media (max-width: $large) {
  .heroImg {
    transform: scale(0.7);
  }

  .heroImgWrap {
    left: -95px;
  }
}

@media (max-width: $tablet) {
  .wrapper {
    background: url("../../assets/images/backgrounds/header-tablet.png")
      center/cover no-repeat;
  }

  .heroWrapper {
    padding: 0 16px;
  }

  .hero {
    flex-direction: column;
    padding: 0 0 105px;
  }

  .heroText {
    width: 100%;
  }

  .heroTextWrap {
    max-width: 538px;
    padding-top: 57px;
  }

  .heroImgWrap {
    width: 100%;
    left: 0px;
    padding-top: 30px;
    height: 706px;
    position: initial;
  }

  .heroImg {
    transform: scale(1);
    margin: 30px auto 0;
    position: relative;
    left: 30px;
    max-width: 747px;
  }

  .heroTitle {
    .heroTitleMain {
      font: 700 110px/121px Lato;
    }
  }
}

@media (max-width: $mobile) {
  .wrapper {
    background: url(../../assets/images/backgrounds/header-mobile.png)
      center/cover no-repeat;
    overflow: hidden;
  }
  .hero {
    padding: 0 0 78px;
  }

  .heroTitle {
    font: 700 32px/35.2px Lato;

    .heroTitleMain {
      font: 700 70px/77px Lato;
    }
  }

  .heroImgWrap {
    padding-top: 0;
    height: 382px;
  }

  .heroImg {
    background: url("../../assets/images/hero/hero-mobile.png") center/cover
      no-repeat;
    height: 325px;
    width: 316.5px;
    transform: scale(1);
    margin: 56px auto 0;
    left: 0;
  }
}
