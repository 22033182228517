@import "../../styles/_vars";

.wrapper {
  position: relative;
  top: -56px;
  border-radius: 60px 60px 0px 0px;
  z-index: 2;
  background: #ffff;
}

.contentWrapper {
  max-width: 1450px;
  padding: 128px 20px 0;
  margin: 0 auto 72px;
}

.sectionTitleWrap {
  max-width: 702px;

  .sectionTitle {
    color: $mainTextColor;
    font: 700 48px/64px Lato;
  }

  .sectionDescription {
    color: $gray-secondary-text;
    font: 400 18px/28px Lato;
    margin-top: 24px;
  }
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
}

@media (max-width: $extra-large) {
  .contentWrapper {
    padding: 80px 24px 0;
    margin: 0 auto 24px;
    max-width: 1350px;
  }

  .sectionTitleWrap {
    .sectionDescription {
      font: 400 16px/24px Lato;
    }
  }
}

@media (max-width: $tablet) {
  .wrapper {
    top: -52px;
    border-radius: 30px 30px 0px 0px;
  }

  .contentWrapper {
    padding: 80px 16px 0;
    margin: 0 auto 28px;
  }

  .sectionTitleWrap {
    max-width: 643px;

    .sectionTitle {
      font: 700 40px/50px Lato;
    }

    .sectionDescription {
      font: 400 18px/28px Lato;
      margin-top: 16px;
    }
  }
}

@media (max-width: $mobile) {
  .wrapper {
    top: -33px;
    border-radius: 24px 24px 0px 0px;
  }

  .contentWrapper {
    padding: 40px 16px 0;
    margin: 0 auto 7px;
  }

  .sectionTitleWrap {
    max-width: 100%;

    .sectionTitle {
      font: 700 32px/50px Lato;
    }

    .sectionDescription {
      font: 400 16px/24px Lato;
      margin-top: 8px;
    }
  }

  .cardsWrapper {
    margin-top: 40px;
  }
}
