@keyframes spin {
  from {
    transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
  }
}

@keyframes Rotate {
  0% {
    transform: rotateZ(0turn);
    -moz-transform: rotateZ(0turn);
  }
  100% {
    transform: rotateZ(-1turn);
    -moz-transform: rotateZ(-1turn);
  }
}

@keyframes spinMotologic {
  0% {
    transform: rotateZ(0deg);
    transform-origin: center 226.9px;
    transform-box: fill-box;
  }
  25% {
    transform: rotateZ(90deg);
    transform-origin: calc(50% + 10.5px) 226.9px;
    transform-box: fill-box;
  }

  50% {
    transform: rotateZ(180deg);
    transform-origin: calc(50% + 10.5px) 226.9px;
    transform-box: fill-box;
  }
  75% {
    transform: rotateZ(270deg);
    transform-origin: calc(50% + 10.5px) 226.9px;
    transform-box: fill-box;
  }
  100% {
    transform: rotateZ(360deg);
    transform-origin: center 226.9px;
    transform-box: fill-box;
  }
}

@keyframes spinTekmetric {
  0% {
    transform: rotateZ(0deg);
    transform-origin: 293.739px 50%;
  }
  25% {
    transform: rotateZ(90deg);
    transform-origin: 293.739px calc(50% + 9px);
  }

  50% {
    transform: rotateZ(180deg);
    transform-origin: 293.739px calc(50% + 9px);
  }
  75% {
    transform: rotateZ(270deg);
    transform-origin: 293.739px calc(50% + 9px);
  }
  100% {
    transform: rotateZ(360deg);
    transform-origin: 293.739px 50%;
  }
}
