@import "../../styles/_vars";

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../assets/images/backgrounds/header-desktop.png")
    center/cover no-repeat;
}

.heroWrapper {
  width: 100%;
  max-width: 1410px;
  display: flex;
  flex-direction: column;

  .content {
    min-height: 430px;
    position: relative;
    margin: 80px 0 110px;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: $extra-large) {
  .wrapper {
    background: url("../../assets/images/backgrounds/header-extra-large.png")
      center/cover no-repeat;
  }

  .heroWrapper {
    padding: 0 26px;
    .content {
      margin: 128px 0 198px;
      min-height: 450px;
    }
  }
}

@media (max-width: $large) {
  .heroWrapper {
    .content {
      margin: 61px 0 141px;
    }
  }
}

@media (max-width: $tablet) {
  .wrapper {
    background: url("../../assets/images/backgrounds/header-tablet.png")
      center/cover no-repeat;
  }

  .heroWrapper {
    padding: 0 16px;

    .content {
      flex-direction: column;
      margin: 70px 0 114px;
    }
  }
}

@media (max-width: $mobile) {
  .wrapper {
    background: url(../../assets/images/backgrounds/header-mobile.png)
      center/cover no-repeat;
    overflow: hidden;
    .content {
      margin: 45px 0 89px;
    }
  }
}
