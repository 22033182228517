@import "../../styles/_vars";
@import "../../styles/_animations";

$animationDuration: 1.2s;
$animationDurationIcons: 0.6s;

// Subscribe Popup
.subscribePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $popupSubscribeZ;
  background: #13327033;
  backdrop-filter: brightness(85%) blur(10px);

  .popup {
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    overflow: overlay;
    max-height: 100vh;
    display: flex;

    .btnClose {
      padding: 10px;
      z-index: $popupSubscribeZ;
      width: 16px;
      height: 16px;
      cursor: pointer;
      border: 0;
      position: absolute;
      top: 32px;
      right: 32px;
      background: transparent
        url("../../assets/icons/subscribe-popup/i-close.svg") center no-repeat;
      &:hover {
        background: transparent
          url("../../assets/icons/subscribe-popup/i-close-active.svg") center
          no-repeat;
      }
    }
    .divider {
      background: #d4d6e5;
      opacity: 0.3;
    }
    .about {
      height: 100%;
      width: 550px;
      padding: 40px 0 0;
      background: transparent
        url("../../assets/images/subscribe-popup/bg-popup.png") center/cover
        no-repeat;
      display: flex;
      flex-direction: column;
      .aboutHeader {
        padding: 0 85px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .aboutLogo {
          width: 158px;
          height: 54px;
        }

        .aboutRate {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding-top: 6px;
          img {
            height: 18px;
          }
          p {
            font: 400 12px Lato;
            color: #e9cdce;
            margin-top: 8px;
            margin-top: 10px;
          }
        }
      }
      .dividerWrap {
        margin: 24px 85px;
      }
      .aboutList {
        padding: 0 84px;
        .aboutListItem {
          display: flex;
          align-items: center;
          color: #fff;
          font: 400 16px Lato;
          &:not(:first-child) {
            margin-top: 16px;
          }
          img {
            margin-right: 10px;
          }
        }
      }
      .sliderWrap {
        margin: 48px 0 28px;
      }
      .aboutFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        padding: 24px 0;
        background-color: #0000000d;

        & span {
          font: 700 18px/26px Lato;
          margin-right: 24px;
        }

        .btnTalkUs {
          width: 93px;
          height: 40px;
          background: #ffffff33;
          border-radius: 5px;
          font: 700 14px/24px Lato;
          cursor: pointer;
          border: 0;
          transition: all 0.3s ease-out;
          color: #ffffff;

          &:hover {
            background: #ffffff4d;
          }
          &:active {
            background: #ffffff66;
          }
        }
      }
    }
    .subscribeForm {
      width: 550px;
      padding: 48px 74px 49px;
      text-align: left;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      .popupTitle {
        color: #191919;
        font: 700 36px Lato;
        margin-bottom: 32px;
        align-self: flex-start;
      }

      .fieldRow {
        &:not(:first-of-type) {
          margin-top: 16px;
        }
      }

      div.fieldRow {
        display: flex;
        width: 100%;
        margin-top: 16px;
        .field {
          width: 50%;
          &:not(:first-child) {
            margin-left: 16px;
          }
        }
      }
      .formBtn {
        cursor: pointer;
        width: 100%;
        height: 45px;
        min-height: 45px;
        margin-top: 32px;
        background: #d7282f;
        border-radius: 5px;
        color: #fff;
        font: 700 14px/24px Lato;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-out;

        &:hover {
          background: #c4262c;
        }
        &:active {
          background: #b21f26;
        }
        transition: all 0.3s ease-out;
      }
    }

    .meetingsFormWrapper {
      margin: 75px;
      display: flex;
      justify-content: center;
      align-items: center;

      .meetingsFormContainer {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #cbd6e2;
        border-radius: 10px;
        width: 398px;
        height: 588px;

        .hubspotContainer {
          width: 490px;
          height: 620px;
          flex-shrink: 0;

          iframe {
            height: 620px !important;
          }
        }
      }
    }

    .success {
      width: 550px;
      height: 100%;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .iconSuccess {
        width: 200px;
      }
      .title {
        margin-top: 16px;
        color: #191919;
        font: 700 32px Lato;
      }
      .body {
        color: $gray-secondary-text;
        font: 400 16px/28px Lato;
        max-width: 380px;
        text-align: center;
        margin-top: 16px;
        white-space: pre-wrap;
      }
      .btnSuccess {
        height: 50px;
        background: #dbeefe;
        color: #198af9;
        font: 700 16px Lato;
        padding: 15px 24px;
        border-radius: 5px;
        margin-top: 32px;
        border: 0;
        cursor: pointer;
      }

      .time {
        margin-top: 24px;
        color: #191919;
        font: 700 24px/30px Lato;
        white-space: pre-wrap;
        margin-bottom: 15px;
      }
    }

    .error {
      width: 550px;
      height: 100%;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .iconError {
        width: 150px;
      }

      .title {
        margin-top: 40px;
        color: #191919;
        font: 700 32px Lato;
      }

      .body {
        color: $gray-secondary-text;
        font: 400 16px/28px Lato;
        max-width: 380px;
        text-align: center;
        margin-top: 16px;
        white-space: pre-wrap;

        a {
          font: 500 16px/28px Lato;
          color: #108af9;
          cursor: pointer;
          &:hover {
            color: #1579e1;
          }
          &:active {
            color: #0462b8;
          }
        }
      }

      .btnError {
        height: 50px;
        background: #d7282f;
        color: #ffffff;
        font: 700 16px Lato;
        padding: 15px 24px;
        border-radius: 5px;
        margin: 30px 0 12px;
        border: 0;
        cursor: pointer;
        &:hover {
          background: #c4262c;
        }
        &:active {
          background: #b21f26;
        }
      }
    }
  }
}

// Carousel
.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 35px;
  .slidesWrap {
    border: 1px solid #ffffff4d;
    background: #ffffff33;
    border-radius: 30px;
    opacity: 0.9;
    margin: 0 45px;
    position: relative;
    padding: 51px 45px 40px;
    height: 334px;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    .btnPrevWrap,
    .btnNextWrap {
      position: absolute;
      top: 44%;
      background: #c4262c;
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    .btnPrevWrap {
      left: -17px;
    }
    .btnNextWrap {
      right: -17px;
    }

    .btnPrev,
    .btnNext {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: #ffffff33
        url("../../assets/icons/subscribe-popup/i-arrow-left-right-white.svg")
        center 10px no-repeat;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: 1px solid #ffffff4d;
      border-radius: 50%;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      transition: background-color 0.3s;
      &:hover {
        background: transparent
          url("../../assets/icons/subscribe-popup/i-arrow-left-right-red.svg")
          center 10px no-repeat;
        background-color: #ffffff;
      }
      &:active {
        background-color: #e3dcdc;
      }
    }
    .btnPrev {
      transform: rotate(180deg);
    }

    .icons {
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 0px;
      position: relative;
      width: 112px;
      .icon {
        width: 36px;
        height: 36px;
        position: relative;
        transition: all $animationDurationIcons ease-out;
        position: absolute;

        &.iconActive {
          width: 54px;
          height: 54px;
          z-index: 11;
        }
        &.order0 {
          order: 0;
          left: 0;
          z-index: 8;
        }
        &.order1 {
          order: 1;
          left: 32px;
          z-index: 11;
        }
        &.order2 {
          order: 2;
          left: 84px;
          z-index: 9;
        }
      }
    }
  }
  .slides {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 2;
    padding-top: 46px;
  }
  .indicators {
    margin-top: 27px;
    display: flex;
    cursor: pointer;
    .indicator {
      cursor: pointer;
      width: 6px;
      height: 6px;
      background: #d8dbe9;
      opacity: 0.6;
      border-radius: 6px;
      transition: width $animationDurationIcons ease-out;
      &:not(:first-child) {
        margin-left: 8px;
      }
      &.indicatorActive {
        width: 16px;
        opacity: 1;
      }
    }
  }
}

.slide {
  display: none;
  height: 100%;
  &.slideActive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  &.slideActive.slideFromLeft {
    animation: slide-in-from-left $animationDuration
      cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &.slideActive.slideFromRight {
    animation: slide-in-from-right $animationDuration
      cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .text {
    font: 500 13px/24px Lato;
    color: #ffffff;
    text-align: center;
  }
  .author {
    margin-top: 24px;
    color: #ffffff;
    font: 600 13px Lato;
  }
}

@media (min-width: $tablet + 1) and (max-width: $containerWidth) {
  .subscribePopup {
    .popup {
      .subscribeForm {
        padding: 29px 40px;
      }
      .about {
        padding: 30px 0 0;
      }
    }
  }
}

@media (max-width: $large) {
  .subscribePopup {
    .popup {
      .about {
        width: 500px;
        .aboutHeader {
          padding: 0 60px;
        }
        .dividerWrap {
          margin: 24px 60px;
        }
        .aboutList {
          padding: 0 60px;
        }
        .sliderWrap {
          .carousel {
            padding: 0 15px;
          }
        }
      }
      .meetingsFormWrapper {
        margin: 50px;
      }
    }
  }
}

@media (max-width: $tablet) {
  .subscribePopup {
    .popup {
      .about {
        display: none;
      }
    }
  }
}

@media (max-width: $mobile) {
  .subscribePopup {
    .popup {
      bottom: 0;
      height: 100%;
      .subscribeForm {
        padding: 40px 20px;
        width: 100vw;
        .popupTitle {
          margin-bottom: 30px;
          font-size: 30px;
        }
      }

      .meetingsFormWrapper {
        margin: 0px;
        flex-wrap: wrap;
        margin-top: 60px;
        align-items: flex-start;

        .meetingsFormContainer {
          border: none;
          border-radius: 0;
          height: 556px;
          max-width: 100vw;
          align-items: flex-end;

          .hubspotContainer {
            max-width: calc(100vw + 10px);
            height: 602px;
          }
        }
      }

      .success {
        justify-content: flex-start;
        padding-top: 32px;

        .iconSuccess {
          width: 50%;
        }

        .title {
          margin-top: 8px;
          font: 700 24px Lato;
        }

        .body {
          max-width: 80%;
          margin-top: 12px;
        }

        .time {
          margin-top: 22px;
          font: 700 20px/26px Lato;
          text-align: center;
        }
      }

      .error {
        justify-content: flex-start;
        padding-top: 32px;

        .iconError {
          width: 37%;
        }

        .title {
          margin-top: 32px;
          font: 700 24px Lato;
        }

        .body {
          max-width: 82%;
          margin-top: 14px;
          white-space: initial;
        }

        .btnError {
          margin: 22px 0 5px;
        }
      }
    }
  }
}

@media (max-width: 410px) {
  .subscribePopup {
    .popup {
      border-radius: 0;
    }
  }
}

.iconCheck {
  width: 15px;
  margin-right: 10px;
}
.loader {
  width: 20px;
  animation: spin 1s infinite linear;
}
