::-webkit-scrollbar {
  width: 10px;
  background: transparent;
  margin-left: 5px;
}

*::-webkit-scrollbar-thumb {
  background: #adb1c9;
  border-radius: 5px;
}
