@import "../../styles/_vars";

body:has(.background.open) {
  overflow: hidden;
}

.background {
  z-index: -10;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #13327033;
  backdrop-filter: brightness(85%) blur(10px);

  &.open {
    z-index: 10;
  }

  .wrapper {
    position: relative;
    width: 500px;
    height: 700px;
    max-height: 100vh;
    transition: all 0.2s;

    &.expanded {
      width: 100%;
      height: 100%;

      .formContainer {
        border-radius: 0;
      }

      .buttonsContainer {
        right: 18px;
        top: 18px;

        .button {
          background-color: #0000001a;

          &:hover {
            background-color: #00000033;
          }
          &:active {
            background-color: #0000004d;
          }
        }
      }
    }

    .formContainer {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 20px;
      overflow: hidden;
    }

    .buttonsContainer {
      position: absolute;
      right: -50px;
      top: 0;

      .button {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-bottom: 10px;
        background-color: #fff;
        background-size: 100%;
        text-align: center;
        transition: background 0.2s;
        cursor: pointer;

        &:hover {
          background-color: #ffffffcc;
        }
        &:active {
          background-color: #ffffff99;
        }

        &.close {
          background-image: url(../../assets/icons/webinar/close.svg);
        }
        &.expand {
          background-image: url(../../assets/icons/webinar/expand.svg);
        }
        &.collapse {
          background-image: url(../../assets/icons/webinar/collapse.svg);
        }
      }
    }
  }
}

@media (max-width: 899px) {
  .background {
    .wrapper.expanded {
      .buttonsContainer {
        top: 150px;
      }
    }
  }
}
@media (max-width: $mobile) {
  .background {
    .wrapper {
      width: 100%;
      height: 100%;

      .formContainer {
        border-radius: 0;
      }

      .buttonsContainer {
        right: 18px;
        top: 150px;

        .button {
          background-color: #0000001a;

          &:hover {
            background-color: #00000033;
          }
          &:active {
            background-color: #0000004d;
          }

          &.expand,
          &.collapse {
            display: none;
          }
        }
      }
    }
  }
}
