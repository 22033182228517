@import "./typography";
@import "./_reset";
@import "./_vars";
@import "./_scrollbar.scss";

body {
  background: #ffff;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased !important;
  -webkit-overflow-scrolling: touch;
  overflow: overlay;
}
.container {
  max-width: $containerWidth;
}
