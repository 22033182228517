@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato/Lato-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato/Lato-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato/Lato-Semibold.ttf");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato/Lato-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/Lato/Lato-Heavy.ttf");
  font-style: normal;
  font-weight: 900;
}
