@import "../../styles/_vars";

.footerWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../assets/images/backgrounds/footer-desktop.png")
    center/cover no-repeat;
}

.footer {
  width: 100%;
  max-width: $containerWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 40px;
}

.logos {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    margin-bottom: 24px;
  }
}

.copyright {
  margin-top: 40px;
  text-align: center;
  color: #95a1b2;
  font: 400 14px/14px Lato;
}

@media (max-width: $extra-large) {
  .footerWrapper {
    background: url("../../assets/images/backgrounds/footer-extra-large.png")
      center/cover no-repeat;
  }
}

@media (max-width: $tablet) {
  .footerWrapper {
    background: url("../../assets/images/backgrounds/footer-tablet.png")
      center/cover no-repeat;
  }
}

@media (max-width: $mobile) {
  .footerWrapper {
    background: url("../../assets/images/backgrounds/footer-mobile.png")
      center/cover no-repeat;
  }

  .footer {
    padding: 24px 0 34px;
  }

  .copyright {
    margin-top: 24px;
    line-height: 20px;
    max-width: 275px;
  }
}
