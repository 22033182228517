*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;

  img {
    border: none;
  }
}

a,
button,
input[type="button"] {
  &:focus {
    outline: none;
  }
}

ul {
  list-style: none;
}

input[type="number"],
input[type="password"],
input[type="text"],
textarea {
  -webkit-appearance: none;
}

svg * {
  transform-box: fill-box;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
